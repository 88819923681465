import { PageProps } from 'gatsby';
import compact from 'lodash/compact';
import { PageData } from 'packages/innedit';
import React, { FC } from 'react';

import Page from '~/components/List/Item/Page';
import List from '~/containers/Espace/List';
import params from '~/params/page.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useChannel from '~/utils/useChannel';

const PagesPage: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
  params: { channelId, espaceId },
}) => {
  const [channel, items] = useChannel({
    channelId,
    espaceId,
    pathname: location.pathname,
  });

  const model = new PageData({
    channelId,
    espaceId,
    params,
    orderDirection: 'desc',
    orderField: 'isHomePage',
    wheres: {
      parent: '',
    },
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <List
        allowSorting
        filters={compact([
          {
            label: 'Caché',
            multiple: true,
            name: 'hidden',
            options: [
              {
                label: 'Oui',
                value: true,
              },
              {
                label: 'Non',
                value: false,
              },
            ],
          },
        ])}
        formParams={{ channelId }}
        itemList={Page}
        model={model}
        search={location.search}
        subMenu={items}
        title={channel?.label}
        user={user}
      />
    </TemplateEspace>
  );
};

export default requireEspace(PagesPage);
